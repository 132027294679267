import React from "react";
import Loader from "../../components/Loader/Loader";
import "./menu.css";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <Loader>
      {/* <Link to={"/jos-nesto"} className="menu-div">
        <p className="menu-p">JOŠ NEŠTO...</p>
      </Link> */}

      <Link to={"/poruka"} className="menu-div">
        <p className="menu-p">PORUKA</p>
      </Link>

      {/* <Link to={"/rodjendan"} className="menu-div">
        <p className="menu-p">ROĐENDAN</p>
      </Link> */}
    </Loader>
  );
};

export default Menu;
