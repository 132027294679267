import React from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const NotFound = () => {
  return (
    <Loader>
      <div className="not-found-div">
        <h1 className="not-found-h">404</h1>

        <Link to={"/"} className="not-found-link">
          KLINKI
        </Link>
      </div>
    </Loader>
  );
};

export default NotFound;
