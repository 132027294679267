import React, { useEffect, useRef, useState } from "react";
import "./loader.css";
import flower from "../../assets/flower.png";
import nd from "../../assets/nd.png";
import potpis from "../../assets/signature.png";
import close from "../../assets/close.png";
import { Link } from "react-router-dom";
import back from "../../assets/arrow.png";

const Loader = ({ children, showModal, setShowModal, showBack }) => {
  const containerRef = useRef(null);
  const logoRef = useRef(null);
  const contentRef = useRef(null);
  const [showBackDelayed, setShowBackDelayed] = useState(false);

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (containerRef.current) {
        containerRef.current.classList.add("change-color"); // Trigger the background color change
      }
      if (logoRef.current) {
        logoRef.current.classList.add("move-to-top"); // Move the logo to the top
      }
      if (contentRef.current) {
        contentRef.current.classList.add("show-content"); // Make the content visible
      }
    };

    // Ensure the event listener is attached to the logo for animation end
    const logoElem = logoRef.current;
    if (logoElem) {
      logoElem.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (logoElem) {
        logoElem.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (showBack) {
      const timer = setTimeout(() => {
        setShowBackDelayed(true);
      }, 4000);
      return () => clearTimeout(timer);
    } else {
      setShowBackDelayed(false);
    }
  }, [showBack]);

  return (
    <div ref={containerRef} className="page-container">
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content animate__animated animate__zoomIn animate__faster">
            <Link target={"_blank"} to={"/poruka"}>
              <img
                onClick={() => {
                  setShowModal(false);
                }}
                className="modal-close-img"
                src={close}
              />
            </Link>
            <img src={nd} />
          </div>
        </div>
      )}

      <div className="loader-container">
        {showBackDelayed && (
          <Link to={"/"} className="loader-back-link">
            <img className="loader-back-img" src={back} />
          </Link>
        )}

        <img ref={logoRef} className="loader-logo" src={flower} alt="Loading" />
        <div ref={contentRef} className="loader-content-div">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Loader;
