import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import Rodjendan from "./pages/Rodjendan/Rodjendan";
import NotFound from "./pages/NotFound";
import { Poruka } from "./pages/Poruka/Poruka";
import Menu from "./pages/Menu/Menu";
import JosNesto from "./pages/JosNesto/JosNesto";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Menu />} />
          {/* <Route path="/jos-nesto" element={<JosNesto />} /> */}
          <Route path="/poruka" element={<Poruka />} />
          {/* <Route path="/rodjendan" element={<Rodjendan />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
