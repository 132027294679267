import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import "./poruka.css";
import axios from "axios";
import { ClipLoader } from "react-spinners";

export const Poruka = () => {
  const [poruka, setPoruka] = useState("");
  const [loading, setLoading] = useState(false);

  const sendPoruka = async () => {
    if (poruka === "") {
      return;
    }
    try {
      setLoading(true);
      await axios.post("https://j-server-2ab6073b83aa.herokuapp.com/poruka", {
        poruka,
      });
      setPoruka("");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sendNot = async () => {
      try {
        await axios.get("https://j-server-2ab6073b83aa.herokuapp.com/poruka");
      } catch (error) {}
    };

    sendNot();
  }, []);

  return (
    <Loader showBack={true}>
      <textarea
        value={poruka}
        onChange={(e) => {
          setPoruka(e.target.value);
        }}
        placeholder="Poruka..."
        className="poruka-textarea"
      />

      <div onClick={sendPoruka} className="not-found-link">
        {loading === true ? <ClipLoader size={15} color={"#fff"} /> : "Pošalji"}
      </div>

      {/* <div className="odgovor-div">
        <div className="odgovor-name-div">
          <h3 className="odgovor-name">SECRET ADMIRER</h3>
        </div>

        <p className="odgovor-p">Odgovor</p>
      </div> */}
    </Loader>
  );
};
